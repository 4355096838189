.popover-section .popover-box {
  font-size: 1.5rem;
  color: #fff;
  width: 100%;
  text-align: left;
  padding: 1px 0;
  border-bottom: 1px solid #444;
}

.popover-section .popover-box span {
  color: #fff;
}

.popover-section .popover-box span.ml-auto {
  font-size: 1.5rem;
  font-weight: bold;
}

.popover-section .popover-box:last-child {
  border-bottom: none; /* Son öğenin altında sınır çizgisi olmayacak */
}

.circle-button {
  background-color: #ffcc00;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column; /* Bu satır, içeriğin dikey olarak hizalanmasını sağlar */
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
}

.circle-button p {
  margin: 0;
  padding: 0;
}

.circle-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.small-text {
  font-size: 0.75rem;
  color: #333;
  margin-top: 4px; /* "7/24" yazısını biraz ayırmak için */
}

/* Sayfanın altına gri renkte bir border eklemek için */
.page-container {
  border-bottom: 144px solid #ffffff; /* Gri renkte border */
  padding-top: 40px; /* Border'dan biraz boşluk bırakmak için */
}


/* Mobil cihazlar için ortalama ve yığma */
@media (max-width: 767.98px) {
  .circle-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .circle-button {
    width: 100px;
    height: 100px;
    margin: 10px 0;
  }

    /* #referanslar {
    order: 2;
  } */
  #hizmetlerimiz {
    order: 1;
  }
}


