/* Offcanvas genel stilleri */
.offcanvas-header {
  background-color: black; /* Koyu siyah arka plan rengi */
  color: #ffffff; /* Beyaz metin rengi */
  display: flex;
  justify-content: space-between;
  padding-right: 50px; /* Kapat butonu ile başlık arasında boşluk */
}

.offcanvas-body {
  background-color: black; /* Koyu siyah arka plan rengi */
  color: #ffffff; /* Beyaz metin rengi */
}

/* Button stil düzenlemeleri */
.offcanvas-header .btn {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

.calc-btn{
  padding: 10px;
}

.offcanvas-header .btn:hover {
  background-color: #333333; /* Hover durumda daha açık siyah */
}

/* Tablo stil düzenlemeleri */
.table {
  background-color: #ffffff; /* Beyaz arka plan rengi */
  color: #000000; /* Siyah metin rengi */
  border: 1px solid #000000; /* Siyah border */
}

.table thead th {
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  border-color: #000000;
}

.table tbody td {
  text-align: center;
  vertical-align: middle;
  border-color: #000000;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

/* Pagination stil düzenlemeleri */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.pagination .page-item .page-link {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

.pagination .page-item.active .page-link {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
}

.pagination .page-item.disabled .page-link {
  background-color: #6c757d;
  color: #ffffff;
  border: 1px solid #6c757d;
}

/* Responsive düzenlemeler */
@media (max-width: 768px) {
  .table {
    font-size: 0.9rem;
  }

  .offcanvas-header {
    padding-right: 10px;
  }

  .pagination .page-link {
    padding: 5px 8px;
  }
}

@media (max-width: 576px) {
  .table {
    font-size: 0.8rem;
  }

  .pagination .page-link {
    padding: 4px 6px;
  }
}
