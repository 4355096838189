/* Login.css */


.login-box {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
 
}

.login-title {

  color: white; /* Yazı rengini beyaz yapıyoruz */
  padding: 0px;
  border-radius: 4px;
}

.login-input {
  border-radius: 25px;
  border: 1px solid #ccc;
  padding: 10px 20px;
}

.login-button {
  border-radius: 25px;
  font-weight: bold;
}

.social-button {
  border-radius: 25px;
  font-weight: bold;
  border: 1px solid #ddd;
  background-color: white;
  padding: 10px 0; /* Butonların içeriklerini ortalamak için padding ayarlandı */
}

.social-button .me-2 {
  margin-right: 10px; /* İkon ile metin arasındaki boşluk */
}

.social-button:hover {
  background-color: #f0f0f0;
}

.text-warning {
  font-weight: bold;
}

@media (max-width: 768px) {
  .login-box {
    padding: 20px;
  }

  .login-input, .login-button, .social-button {
    font-size: 14px;
    padding: 8px 15px;
  }
}

@media (max-width: 576px) {
  .login-box {
    padding: 15px;
  }

  .login-input, .login-button, .social-button {
    font-size: 12px;
    padding: 6px 10px;
  }
}
