/* Soldan Gelen Animasyon */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-left {
  animation: slideInLeft 1s ease-out forwards;
}

/* Sağdan Gelen Animasyon */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-right {
  animation: slideInRight 1s ease-out forwards;
}

/* Yukarıdan Gelen Animasyon */
@keyframes slideInTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-top {
  animation: slideInTop 1s ease-out forwards;
}

/* Aşağıdan Gelen Animasyon */
@keyframes slideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-bottom {
  animation: slideInBottom 1s ease-out forwards;
}

/* Animasyon sınıfları için genel ayarlar */
.animated {
  animation-duration: 1s; /* Animasyon süresi */
  animation-fill-mode: both; /* Animasyon sonrası durum */
}

/* Soldan gelen animasyon */
.animate-left.animated {
  animation-name: slideInLeft;
}

/* Sağdan gelen animasyon */
.animate-right.animated {
  animation-name: slideInRight;
}

/* Yukarıdan gelen animasyon */
.animate-top.animated {
  animation-name: slideInTop;
}

/* Aşağıdan gelen animasyon */
.animate-bottom.animated {
  animation-name: slideInBottom;
}
