/* swap.css dosyasında yapılacak değişiklikler */



.media-main2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-left: 150px;
  z-index: 0;
}

.media-main2 Image {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-content: center;
  justify-content: center;
  padding-left: 150px;
  z-index: 0;
}

.media-main2 div a {
  width: 48px;
  height: 48px;
  text-align: center;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-main2 div a .icon {
  position: relative;
  color: #262626;
  transition: .5s;
  z-index: 2;
}

.media-main2 div a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.media-main2 div a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .5s;
  background: var(--13, linear-gradient(90deg, #FF7D61 0%, #FFDB59 100%));
  z-index: 2;
}

.media-main2 div a:hover:before {
  top: 0;
}

.media-main2 div:nth-child(1) a:before {
  background: var(--13, linear-gradient(90deg, #FF7D61 0%, #FFDB59 100%));
}

.media-main2 div:nth-child(2) a:before {
  background: var(--13, linear-gradient(90deg, #FF7D61 0%, #FFDB59 100%));
}

.media-main2 div:nth-child(3) a:before {
  background: var(--13, linear-gradient(90deg, #FF7D61 0%, #FFDB59 100%));
}

.media-main2 div:nth-child(4) a:before {
  background: var(--13, linear-gradient(90deg, #FF7D61 0%, #FFDB59 100%));
}
/* Genel mobil ve tablet uyumluluğu sağlamak için çeşitli medya sorguları ekleyelim */
/* swap.css dosyasında yapılacak değişiklikler */
/* swap.css dosyasında yapılacak değişiklikler */

/* Genel mobil ve tablet uyumluluğu sağlamak için çeşitli medya sorguları ekleyelim */

@media (max-width: 768px) {
  .home-container {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  .container2 {
    height: auto;
    text-align: center;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .main-text {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
    line-height: 1.4;
  }

  .kargo-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .kargo-logos .col {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    width: 50%;
    max-width: 150px;
  }

  .ptt, .ups, .yurt {
    width: 80%;
    max-width: 100px;
  }

  .main-img1 {
    margin-top: 30px;
    text-align: center;
    width: 100%;
  }

  .right-box {
    border-left: none;
    border-top: 1px solid gray;
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }

  .arrow-down {
    margin-top: 20px;
  }

  .left-box {
    margin-top: 30px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }
}

@media (max-width: 1024px) {
  .home-container {
    padding: 15px;
    box-sizing: border-box;
  }

  .kargo-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .kargo-logos .col {
    margin-bottom: 15px;
    width: 33%;
    display: flex;
    justify-content: center;
  }

  .ptt, .ups, .yurt {
    width: 70%;
    max-width: 120px;
  }

  .container2 h5 {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home-container {
    display: flex;
    flex-direction: column; /* Dikey düzen */
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .container2 {
    order: 1; /* Üstte yer alır */
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: left; /* Web görünümüne uygun */
  }

  .main-text {
    font-size: 1.8rem;
    text-align: left;
  }

  .kargo-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }

  .kargo-logos .col {
    margin-bottom: 0;
    width: auto;
    display: flex;
    justify-content: center;
  }

  .ptt, .ups, .yurt {
    width: 70%;
    max-width: 150px;
  }

  .main-img1 {
    margin-top: 0;
    height: auto;
    width: 100%;
    text-align: center;
  }

  /* Sağ ve sol kutular */
  .right-box, .left-box {
    order: 2; /* Sağ ve sol kutular üstte yer alır */
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between; /* Sağ ve sola yerleştir */
    align-items: center;
    border: none;
    padding: 10px 0;
    text-align: left;
  }

  .right-box {
    padding-left: 10px;
    border-left: 1px solid gray;
    margin-left: 100px; /* Border çizgisini sola taşır */
  }

  .left-box {
    padding-right: 10px;
    border-right: 1px solid gray;
    margin-right: 0px; /* Border çizgisini sağa taşır */
  }

  /* Section Main - En altta */
  .section-main {
    order: 3;
    width: 75%;
    padding: 15px 0;
    margin-top: 20px;
    text-align: left;
    border-top: 1px solid gray;
    box-sizing: border-box;
  }

  /* Arrow kısmı */
  .arrow-down {
    margin: 5px auto 0 auto; /* Yukarıdan ve aşağıdan boşlukları azalt */
    width: 30px; /* Ok boyutunu küçült */
    height: 30px; /* Yüksekliğini küçült */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow-down svg {
    width: 20px; /* Ok simgesini daha küçük yap */
    height: 20px; /* Yüksekliğini küçült */
    color: white; /* Varsayılan renk */
    transition: transform 0.3s;
  }

  .arrow-down:hover svg {
    transform: scale(1.1); /* Hover efektinde biraz büyüsün */
  }

  /* Buton kısmını küçült ve hizala */
  .arrow-down button {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
}




/* Masaüstü için optimize edilecek kısımlar */
@media (min-width: 1025px) {
  .right-box {
    border-left: 1px solid gray;
    padding: 20px;
  }

  .left-box {
    border-right: 1px solid gray;
    padding: 20px;
  }

  .main-img1 {
    max-height: 60vh;
  }
}

/* Daha ufak mobil cihazlar için özel düzenlemeler */
@media (max-width: 576px) {
  .main-text h5 {
    font-size: 1.2rem;
  }

  .main-text span {
    font-size: 1.4rem;
  }

  .kargo-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .kargo-logos .col {
    display: flex;
    justify-content: center;
    width: 50%;
    max-width: 150px;
    margin-bottom: 15px;
  }

  .ptt, .ups, .yurt {
    width: 80%;
    max-width: 100px;
  }

  .right-box, .left-box {
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  .arrow-down {
    margin-top: 15px;
  }
}
