.footer-section {

  border-top: 2px solid #ffffff; /* Üstte beyaz border */
  padding-bottom: 4px; /* Alta boşluk bırak */
    margin-top: 30px;
}

.footer-section h5 {
  font-size: 1.25rem; /* Başlık boyutu */
  font-weight: bold;
}

.footer-section p, .footer-section small {
  font-size: 0.875rem; /* Küçük yazı boyutu */
  line-height: 1.5; /* Satır yüksekliği */
}

.footer-section ul {
  padding-left: 0;
  list-style: none;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-section .text-warning {
  color: #f0ad4e !important; /* İkonlar için sarı renk */
}

.footer-section .me-2 {
  margin-right: 0.5rem; /* İkonlar ile metin arası boşluk */
}
