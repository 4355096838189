/* Ülke bayrağı ve kodunu yan yana hizalar */
.react-tel-input .country-list .country {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

/* Ülke ismi sola hizalanır */
.react-tel-input .country-list .country-name {
  flex: 1;
  text-align: left;
}

/* Ülke bayrağını ve kodunu yan yana hizalar */
.react-tel-input .country-list .flag {
  margin-right: 5px;
}

.react-tel-input .country-list .dial-code {
  margin-left: auto;
  font-weight: bold;
}
