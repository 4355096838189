/* Genel logo stili */
.logo-img {
  object-fit: contain;
  display: block;
  margin: 0 auto;
  transition: transform 0.3s ease; /* Hover animasyonu */
}

/* Konteyner stili */
.logo-container {
  width: 150px; /* Tüm logolar için sabit genişlik */
  height: 80px; /* Tüm logolar için sabit yükseklik */
  
  border-radius: 50px; /* Yuvarlak köşeler için border-radius */
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 10px;
  margin: 10px; /* Kutular arasında boşluk bırakmak için margin ekliyoruz */
  box-sizing: border-box;
  box-shadow: inset 0 0 5px gray; /* Hafif iç gölge */
  border: 3px solid rgb(224, 210, 210); /* Hafif beyaz border */
}

/* Hover efekti */
.logo-container:hover .logo-img {
  transform: scale(1.4); /* Hover sırasında logo büyüme efekti */
}


.ptt-logo{
    width: 300px;
   
}

.mng-logo{
    width: 200px;
  
}
.ups-logo{
    width: 150px;
  
}

.yurtiKargo-logo{
    width: 200px;
}

.trendyol-logo{
    width: 200px;
}
.fade-appear, .fade-enter {
  opacity: 0;
}
.fade-appear-active, .fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

/* Animate.css */

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-left {
  opacity: 0;
  animation: fadeInLeft 1s forwards;
}

.animate-right {
  opacity: 0;
  animation: fadeInRight 1s forwards;
}

.animated {
  opacity: 1 !important;
}



@media (max-width: 768px) {
    .logo-container {
        width: 150px; /* Konteyner genişliğini küçültüyoruz */
        height: 70px; /* Konteyner yüksekliğini küçültüyoruz */
        margin: 15px; /* Kutular arasında daha az boşluk */
    }
     .trendyol-logo {
        width: 120px; /* Küçük ekranlar için logoların boyutunu küçültüyoruz */

    }
    .ptt-logo{
        width: 200px;
   
    }
    .mng-logo{
        width: 180px;

    }
    .ups-logo{
        width: 100px;

    }
    .yurtiKargo-logo{
        width: 180px;
    }
    .trendyol-logo{
        width: 150px;
    }
}

