/* Remove spin animation */

/* Offcanvas bileşeninin arka planını şeffaf yap */
.custom-offcanvas {
  background-color: transparent !important;
}

/* Backdrop'un arka planını şeffaf yap */
.custom-offcanvas-backdrop {
  background-color: transparent !important;
}

.custom-card {
  opacity: 1; /* Ensure the card is visible */
  transform: scale(1); /* Default scale */
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* Add hover effect to slightly scale the card */
.custom-card:hover {
  transform: scale(1.05); /* Scale the card slightly on hover */
}

/* Container general styles */
.pricing-cargo {
  padding: 2rem 0; /* Space on top and bottom */
}

/* Card alignment and spacing */
.pricing-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Customize card styles */
.custom-card {
  border-radius: 10px; /* Rounded corners */
  background-color: transparent; /* No background color */
  text-align: center; /* Center text */
  width: 15rem;
  height: 325px;
  padding: 1rem;
  box-shadow: 0px 4px 10px gray; /* Slight shadow effect */
}

/* Card image styling */
.custom-card img {
  height: 80px; /* Image height */
  width: auto;
  margin-bottom: 1rem; /* Space between image and content */
}

/* Card title style */
.custom-card .card-title {
  font-size: 2rem; /* Title font size */
  margin-bottom: 0.5rem;
}

/* Card text style */
.custom-card .card-text {
  color: #cccccc; /* Text color */
  font-size: 0.9rem; /* Text font size */
}

/* View prices link */
.custom-card .text-warning {
  font-weight: bold; /* Bold text */
  text-decoration: none; /* No underline */
  transition: color 0.3s ease; /* Color transition */
}

.custom-card .text-warning:hover {
  color: #ffc107; /* Hover effect color */
}

/* Arrow icon styling */
.custom-card .arrow-icon {
  display: block;
  margin: 0.5rem auto 0; /* Space on top and center */
  font-size: 1.2rem; /* Icon size */
}

/* Mobile-specific styles */
@media (max-width: 576px) {
  .pricing-cards {
    margin-bottom: 1.5rem; /* Space between cards */
  }

  .custom-card {
    width: 100%; /* Full width for mobile */
    border-radius: 10px; /* Reduced corner radius */
  }
}

/* Specific logo adjustments for different sizes */
.custom-card.ptt-card img,
.custom-card.mng-card img,
.custom-card.ups-card img,
.custom-card.yurtici-card img {
  height: auto;
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 769px) {
  .custom-card.ptt-card img {
    height: 120px;
  }

  .custom-card.mng-card img {
    height: 110px;
  }

  .custom-card.ups-card img {
    height: 115px;
  }

  .custom-card.yurtici-card img {
    height: 125px;
  }
}

/* Offcanvas styling remains unchanged */
