.calculate-cargo-container {
  background-color: #000000; /* Arka plan rengi */
  color: #ffffff; /* Metin rengi */
  padding: 1px; /* İç boşluk */
  border-radius: 38px; /* Köşe yuvarlama */
  margin-right: -10px;

}
.calculate-cargo-container h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  color: #ffc107; /* Başlık rengi */
}

.calculate-cargo-container .form-control {
  background-color: #fafbfd; /* Giriş alanı arka plan rengi */
  color: #ffffff; /* Giriş alanı metin rengi */
  border: 1px solid #155fa1; /* Sınır rengi */
  border-radius: 4px; /* Köşe yuvarlama */
  margin: 8px;
}

.calculate-cargo-container .form-control::placeholder {
  color: #020000; /* Placeholder rengi */
}

.calculate-cargo-container .form-control:focus {
  background-color: #343a40; /* Odaklanınca arka plan rengi değişmesin */
  border-color: #ffc107; /* Odaklanınca sınır rengi */
  box-shadow: none; /* Odaklanınca gölge olmasın */
}

.calculate-cargo-container .btn-warning {
  background-color: #ffc107; /* Buton arka plan rengi */
  border: none; /* Sınırları kaldır */
  color: #343a40; /* Buton metin rengi */
  font-weight: bold;
  padding: 10px 0;
  transition: background-color 0.3s ease; /* Geçiş animasyonu */
}

.calculate-cargo-container .btn-warning:hover {
  background-color: #e0a800; /* Buton hover rengi */
}

.calculate-cargo-container .result-container {
  margin-top: 10px;
}

.calculate-cargo-container .result-item {
  background-color: #f3e7e7; /* Sonuç arka plan rengi */
  color: #000000; /* Sonuç metin rengi */
  padding: 10px;
  border-radius: 15px; /* Köşe yuvarlama */
  margin-bottom: 1px; /* Alt boşluk */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.calculate-cargo-container .result-item img {
  margin-right: 1px; /* Sağ tarafta boşluk bırak */
}

/* MNG Kargo Logo */
.calculate-cargo-container .result-item img[alt="MNG Kargo"] {
  height: auto;
  width: 150px;
}

/* PTT Kargo Logo */
.calculate-cargo-container .result-item img[alt="PTT Kargo"] {
  height: auto;
  width: 150px;
}

/* Yurtiçi Kargo Logo */
.calculate-cargo-container .result-item img[alt="Yurtiçi Kargo"] {
  height: auto;
  width: 150px;
}

/* UPS Kargo Logo */
.calculate-cargo-container .result-item img[alt="UPS Kargo"] {
  height: auto;
  width: 150px;
}

.calculate-cargo-container .result-item span {
  font-size: 1.2rem;
  font-weight: 600;
}




/* Tüm input alanlarındaki placeholder metinleri siyah */
input::placeholder {
  color: black !important;
  opacity: 1;
}

/* WebKit tarayıcıları (Chrome, Safari) için placeholder metni */
input::-webkit-input-placeholder {
  color: black !important;
}

/* Mozilla Firefox için placeholder metni */
input::-moz-placeholder {
  color: black !important;
}

/* Microsoft Edge için placeholder metni */
input::-ms-input-placeholder {
  color: black !important;
}

/* Internet Explorer 10-11 için placeholder metni */
input:-ms-input-placeholder {
  color: black !important;
}

/* Giriş yapılmış tüm input alanlarındaki metinler siyah */
input {
  color: black !important; /* !important ekleyerek stilin geçerli olmasını sağlıyoruz */
  background-color: white; /* Arka plan beyaz */
}

/* Odaktan çıkıldığında (blur) metin renginin siyah kalmasını sağlamak */
input:focus {
  color: black !important;
  background-color: white;
}

/* Odaklanma dışında da metin siyah kalır */
input:not(:focus) {
  color: black !important;
  background-color: white;
}
