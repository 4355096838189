 .register-form {
        margin-top: 630px;
    }

/* Register bileşeni için aşağıya padding ekleyin */

/* Footer bileşeni için yukarıya margin ekleyin */


@media (max-width: 767px) {
    .register-form {
        margin-top: 1310px;
    }
    footer {
  margin-top: 1340px; /* Footer ile Register bileşeni arasındaki boşluğu artırmak için margin ekleyin */
}
}